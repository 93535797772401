import "./style.css";
import * as dat from "dat.gui";
import * as THREE from "three";
import {
  MapControls,
  OrbitControls,
} from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import dustVertexShader from "./shaders/dust/vertex.glsl";
import dustFragmentShader from "./shaders/dust/fragment.glsl";
import pcScreenVertexShader from "./shaders/screen/vertex.glsl";
import pcScreenFragmentShader from "./shaders/screen/fragment.glsl";
import gsap, { Power2 } from "gsap";

/**
 * Base
 */
// Debug
const debugObject = {};
// const gui = new dat.GUI({
//     width: 400
// })

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Loading Screen
 */
const loadingText = document.querySelector(".loadingText");
const loadingScreen = document.querySelector(".fullScreenLoading");
const nav = document.querySelector(".nav-items");

let sceneReady = false;

const loadingDots = setInterval(() => {
  if (!sceneReady) {
    loadingText.innerText += ".";
  }
}, 1000);

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    window.setTimeout(() => {
      sceneReady = true;

      var tl = gsap.timeline();
      tl.to(loadingText, { y: 100 }, "+=0.7");
      tl.to(loadingScreen, 2, {
        ease: Power2.easeOut,
        y: -window.innerHeight - 100,
      });
      tl.to(nav, 0.7, { opacity: 1 }, "-=0.5");

      clearInterval(loadingDots);
    }, 1500);
  }
);

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader();

// Draco loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("draco/");

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Textures
 */
const bakedTexture = textureLoader.load("main.jpg");
bakedTexture.flipY = false;
bakedTexture.encoding = THREE.sRGBEncoding;
const project1Texture = textureLoader.load("./Projects/Project_1.jpg");
const project2Texture = textureLoader.load("./Projects/Project_2.jpg");
const project3Texture = textureLoader.load("./Projects/Project_3.jpg");
const project4Texture = textureLoader.load("./Projects/Project_4.jpg");
const project5Texture = textureLoader.load("./Projects/Project_5.jpg");

//geometry
const projectSlideGeometry = new THREE.PlaneBufferGeometry(1.5, 1.2);

/**
 * Materials
 */
// Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({
  map: bakedTexture,
  side: THREE.DoubleSide,
});

// Pole light material
const reactLightMaterial = new THREE.MeshBasicMaterial({ color: 0x7fcfff });

// Main Name material
const mainTitleMaterial = new THREE.MeshBasicMaterial({ color: 0xf2d5c4 });

const projectSlideMaterial = new THREE.MeshBasicMaterial({
  color: 0xb2b9c4,
  transparent: true,
  opacity: 0.8,
  map: project1Texture,
});

// Screen light material
debugObject.portalColorStart = "#d97c3c";
debugObject.portalColorEnd = "#ffffff";

const portalLightMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uColorStart: { value: new THREE.Color(debugObject.portalColorStart) },
    uColorEnd: { value: new THREE.Color(debugObject.portalColorEnd) },
  },
  vertexShader: pcScreenVertexShader,
  fragmentShader: pcScreenFragmentShader,
});

/**
 * Model
 */
var projectTitleMeshes = [];
const projectSlide = new THREE.Mesh(projectSlideGeometry, projectSlideMaterial);
projectSlide.position.set(-3.6, 4.1, 1);
projectSlide.rotation.set(0, Math.PI / 2, 0);
projectTitleMeshes.push(projectSlide);

scene.add(projectSlide);

gltfLoader.load("main.glb", (gltf) => {
  scene.add(gltf.scene);

  for (const child of gltf.scene.children) {
    if (child.name.startsWith("Project_") && child.name !== "Project_0") {
      projectTitleMeshes.push(child);
    }
  }

  // Get each object
  const bakedMesh = gltf.scene.children.find((child) => child.name === "baked");
  const pcLightMesh = gltf.scene.children.find(
    (child) => child.name === "Pc_Screen"
  );
  const iconLightMesh = gltf.scene.children.find(
    (child) => child.name === "react"
  );
  const mainName = gltf.scene.children.find((child) => child.name === "Name");
  const mainTitle = gltf.scene.children.find(
    (child) => child.name === "webdev"
  );

  // Apply materials
  bakedMesh.material = bakedMaterial;
  pcLightMesh.material = portalLightMaterial;
  iconLightMesh.material = reactLightMaterial;
  mainName.material = mainTitleMaterial;
  mainTitle.material = mainTitleMaterial;
});

/**
 * Pixi dust
 */
// Geometry
const dustGeometry = new THREE.BufferGeometry();
const dustCount = 40;
const positionArray = new Float32Array(dustCount * 3);
const scaleArray = new Float32Array(dustCount);

function generateRandomInteger(min, max) {
  min *= 10;
  max *= 10;
  return Math.floor(min + Math.random() * (max - min + 1)) * 0.1;
}

for (let i = 0; i < dustCount; i++) {
  positionArray[i * 3 + 0] = generateRandomInteger(-2, 9);
  positionArray[i * 3 + 1] = generateRandomInteger(
    Math.max(0, 4 - (9 - positionArray[i * 3 + 0])),
    Math.max(1, 7.5 - (9 - positionArray[i * 3 + 0]) * 0.5)
  );
  positionArray[i * 3 + 2] = generateRandomInteger(
    1,
    5 + Math.max(1, (5 - positionArray[i * 3 + 0]) * 0.5)
  );

  scaleArray[i] = Math.random();
}

dustGeometry.setAttribute(
  "position",
  new THREE.BufferAttribute(positionArray, 3)
);
dustGeometry.setAttribute("aScale", new THREE.BufferAttribute(scaleArray, 1));

// Material
const dustMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
    uSize: { value: 200 },
  },
  vertexShader: dustVertexShader,
  fragmentShader: dustFragmentShader,
  transparent: true,
  blending: THREE.AdditiveBlending,
  depthWrite: false,
});

// Points
const dust = new THREE.Points(dustGeometry, dustMaterial);
scene.add(dust);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Update dust
  dustMaterial.uniforms.uPixelRatio.value = Math.min(
    window.devicePixelRatio,
    2
  );
});

/**
 * Camera
 */
// Camera Predefine values
debugObject.camera1Position = new THREE.Vector3(0, 5.195, 15.367);
debugObject.camera1Rotation = new THREE.Vector3(-0.192, 0, 0);
debugObject.camera2Position = new THREE.Vector3(-0.031, 5.271, 4.839);
debugObject.camera2Rotation = new THREE.Vector3(-0.282, 0.764, 0.198);
debugObject.camera3Position = new THREE.Vector3(2.246, 1.3, 6.475);
debugObject.camera3Rotation = new THREE.Vector3(-0.278, -0.372, -0.103);
debugObject.camera4Position = new THREE.Vector3(0, 4.049, 3.633);
debugObject.camera4Rotation = new THREE.Vector3(-0.155, 0.032, 0.005);

const activeCamera = {};
activeCamera.position = debugObject.camera1Position;
activeCamera.rotation = debugObject.camera1Rotation;

// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  100
);

movedSlide(1);

scene.add(camera);

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

debugObject.clearColor = "#0c0a0a";
renderer.setClearColor(debugObject.clearColor);

/**
 * Animations
 */
// Event Listners
const mouse = new THREE.Vector2();
var cameraShakeEnable = true;

// moving camera on mouse move
var cameraShake = window.addEventListener("mousemove", (e) => {
  if (cameraShakeEnable) {
    var mouseX = (e.clientX / sizes.width) * 2 - 1;
    var mouseY = -(e.clientY / sizes.height) * 2 + 1;
    gsap.to(camera.position, {
      x: activeCamera.position.x + mouseX / 7,
      y: activeCamera.position.y + mouseY / 7,
    });
    mouse.set(mouseX, mouseY);
  }
});

// moving camera on SCROLL
let scrollDiv = document.getElementById("fullPage");
var activeSlide = 0;

scrollDiv.addEventListener("scroll", () => {
  var no = scrollDiv.scrollTop / sizes.height + 1;
  movedSlide(no);
});

function movedSlide(activeNo) {
  activeSlide = Math.floor(activeNo);
  clickActive = false;

  // Updating Nav
  let divs = document.getElementsByClassName("nav-item");
  for (const div of divs) {
    div.classList.remove("active");
  }

  let message = document.getElementsByClassName("pcScreen")[0];
  gsap.to(message, 0.2, { scale: 0.5, opacity: 0, display: "none" });
  gsap.to(projectSlide.position, 0.7, { z: 1 });

  // Moving Camera
  if (activeNo <= 1.5) {
    divs[0].classList.add("active");
    moveCamera(debugObject.camera1Position, debugObject.camera1Rotation);
  }
  if (1.5 < activeNo && activeNo <= 2.5) {
    divs[1].classList.add("active");
    moveCamera(debugObject.camera2Position, debugObject.camera2Rotation);
  }
  if (2.5 < activeNo && activeNo <= 3.5) {
    divs[2].classList.add("active");
    moveCamera(debugObject.camera3Position, debugObject.camera3Rotation);
  }
  if (3.5 < activeNo && activeNo <= 4.5) {
    divs[3].classList.add("active");
    moveCamera(debugObject.camera4Position, debugObject.camera4Rotation);
  }

  if (activeNo == 2) {
    clickActive = true;
    gsap.to(projectSlide.position, 0.7, { z: 3, delay: 0.5 });
  }

  // setTimeout(() => {
  //     if (activeSlide == 4) {
  //     }
  //     else {
  //         gsap.to(message, 0.2, { scale: 0.5, opacity: 0, display: 'none' })
  //     }
  // }, 3000);
}

function moveCamera(targetPosition, targetRotation) {
  cameraShakeEnable = false;
  let message = document.getElementsByClassName("pcScreen")[0];

  gsap.to(camera.position, {
    x: targetPosition.x,
    y: targetPosition.y,
    z: targetPosition.z,
    duration: 1.8,
    onComplete: () => {
      // enambling camera shake and showing contact div on last
      setTimeout(() => {
        cameraShakeEnable = true;
      }, 500);
      setTimeout(() => {
        if (activeSlide == 4) {
          gsap.to(message, 0.2, { scale: 1, opacity: 1, display: "flex" });
        }
      }, 1200);
    },
  });
  gsap.to(camera.rotation, {
    x: targetRotation.x,
    y: targetRotation.y,
    z: targetRotation.z,
    duration: 1.5,
  });
  activeCamera.position = targetPosition;
  activeCamera.rotation = targetRotation;
}

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster();
var intersects = [];

function projectShowcase(name) {
  switch (name) {
    case "Project_1":
      projectSlideMaterial.map = project1Texture;
      projectSlide.name = "Project_1";
      break;
    case "Project_2":
      projectSlideMaterial.map = project2Texture;
      projectSlide.name = "Project_2";
      break;
    case "Project_3":
      projectSlideMaterial.map = project3Texture;
      projectSlide.name = "Project_3";
      break;
    case "Project_4":
      projectSlideMaterial.map = project4Texture;
      projectSlide.name = "Project_4";
      break;
    case "Project_5":
      projectSlideMaterial.map = project5Texture;
      projectSlide.name = "Project_5";
      break;

    default:
      break;
  }
}

var clickActive = false;

window.addEventListener("click", () => {
  raycaster.setFromCamera(mouse, camera);
  let temp = raycaster.intersectObjects(projectTitleMeshes);
  if (temp.length) {
    let name = temp[0].object.name;
    if (name == "Project_display") {
      name = projectSlideMaterial.map.name;
    }
    if (clickActive) {
      switch (name) {
        case "Project_1":
          window.open("https://direct-div.vercel.app", "_blank");
          break;
        case "Project_2":
          window.open("https://bigbullsecurity.com", "_blank");
          break;
        case "Project_3":
          window.open("https://group-div.vercel.app", "_blank");
          break;
        case "Project_4":
          window.open("https://share.quadqode.com", "_blank");
          break;
        case "Project_5":
          window.open("https://quadqode.com", "_blank");
          break;

        default:
          break;
      }
    }
  }
});

/**
 * Animate Frames
 */

const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update materials
  portalLightMaterial.uniforms.uTime.value = elapsedTime;
  dustMaterial.uniforms.uTime.value = elapsedTime;

  // Update controls
  // controls.update()

  // Cast a ray from the mouse and handle events
  if (activeSlide == 2) {
    raycaster.setFromCamera(mouse, camera);

    intersects = [];
    intersects = raycaster.intersectObjects(projectTitleMeshes);

    if (intersects.length) {
      clickActive = true;
      projectShowcase(intersects[0].object.name);
    } else {
      clickActive = false;
    }
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
